<template>
  <div id="footer-container">
    <div id="footer">
      <div class="footer-icon">
        <a href="https://www.facebook.com/EESTEC.Bucuresti/"
          ><b-icon icon="facebook"></b-icon
        ></a>
      </div>
      <div class="footer-icon">
        <a href="https://www.instagram.com/eestec_lc_bucharest/"
          ><b-icon icon="instagram"></b-icon
        ></a>
      </div>
      <div class="footer-icon">
        <a href="https://www.linkedin.com/company/eestec-bucharest"
          ><b-icon icon="linkedin"></b-icon
        ></a>
      </div>
      <div class="footer-icon">
        <a href="https://www.youtube.com/channel/UCrhVpROahILs-Y-4Mf4cYBw"
          ><b-icon icon="youtube"> </b-icon
        ></a>
      </div>
    </div>
    <div class="footer-label">
      <b-icon icon="map-marker"> </b-icon> Splaiul Independenței, nr. 313,
      AN204bis, UPB
    </div>
    <div class="footer-label"><b-icon icon="phone"> </b-icon> 0728 074 355</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#footer-container {
  background-color: $primary-color;
  position: relative;
}

#footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
  flex-wrap: nowrap;
  flex-direction: row;
  background-color: $primary-color;
  width: 100%;
}
.footer-icon {
  span.icon {
    color: $primary-color !important;
  }
  a {
    height: 24px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-color: $primary-color;
  border-style: solid;
  border-radius: 50%;
  align-content: center;
  color: $primary-color;
  width: 36px;
  height: 36px;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 1%;
  margin-top: 1%;
}
.footer-icon:hover {
  background-color: lightgray;
}
.footer-label {
  background-color: $primary-color;
  color: white;
  width: 100%;
  span.icon {
    color: #fff !important;
  }
  align-content: center;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-bottom: 1%;
}

@media (max-width: 900px) {
  #footer-container {
    top: 3.5vh;
    padding-bottom: 3vh;
  }
}
</style>
