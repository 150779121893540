<template>
  <div id = "partners-page">
    <P id="title">Parteneri</P>
    <div id = "partners">
    <b-loading :is-full-page="fullPage" :active.sync="getLoadingPartners"></b-loading>
    <div v-for="(partnerType, index) in getPartners" :key="index" class = "partner-type">
        <h2 v-if="partnerType.content.length != 0">{{$t(partnerType.name)}}</h2>
        <div v-if="partnerType.content.length != 0" class="partners-list">
          <div class="partner-container" v-for="(partner, index) in partnerType.content" :key="index">
            <a class="partner" :href="partner.link">
              <img :src="partner.logo" :alt="partner.name">
            </a>
          </div>
        </div>
    </div>
  </div>


  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

  export default {
    components: {
    },
    data () {
      return {
        fullPage: false
      }
    },
    computed: {
        ...mapGetters('partnersModule', ['getPartners', 'getLoadingPartners'])
    },
    methods: {
        ...mapActions('partnersModule', ['requestPartners'])
    },
    mounted() {
        this.requestPartners();
    }
  }
</script>

<style lang="scss" scoped>
#partners {
  @extend %eestec-page;
}
.partner-type {
  margin: auto;
  width: 80%;
}

.partner-row img{
  height: 100%;
}

h2 {
  display: block;
  font-weight: bold;
  font-size:  5vh;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 50px;
}

.partners-list{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.partner{
  margin: 0.5em 0.75em;
}

#title{
  margin-top: 80px;
  font-size: 40px;
}

@media screen and (max-width: 600px) {
  .partner-container {
    margin-top: 20px;
  }
}

.partner > img {
  //filter: grayscale(100%);
  //opacity: 0.66;
  transition: all 0.3s ease;
  height: 25vh;
}

.partner > img:hover{
  filter: grayscale(0%);
  opacity: 1;
}

.partner-type:first-child img {
  max-height: 25vh;
}

.partner-type:nth-child(2) img {
  max-height: 8vh;
}

.partner-type:nth-child(3) img {
  max-height: 7vh;
}

.partner-type:nth-child(4) img {
  max-height: 7vh;
}

.partner-type:nth-child(5) img {
  max-height: 7vh;
}

.partner-type:nth-child(6) img {
  max-height: 7vh;
}

.partner-type:nth-child(7) img {
  max-height: 7vh;
}

.partner-type:nth-child(8) img {
  max-height: 6vh;
}

.partner-type:nth-child(9) img {
  max-height: 6vh;
}

.partner-type:nth-child(10) img {
  max-height: 6vh;
}
</style>