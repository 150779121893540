let events = [
    {
        title:"2003",
        image:"https://i.imgur.com/01JznX6.jpg",
        text:"Energy Always a Challenge"
    },
    {
        title:"2005",
        image:"https://i.imgur.com/TsNgL9w.png",
        text:"Balkan Power Summer School"
    },
    {
        title:"2005",
        image:"https://i.imgur.com/Q2AWGcb.jpg",
        text:"Energy Always a Challenge 2"
    },
    {
        title:"2005",
        image:"https://i.imgur.com/9hKtpEq.jpg",
        text:"Cut the Wires, Ride the Wave"
    },
    {
  	title:"2006",
        image:"https://i.imgur.com/aZDCa7m.png",
        text:"Home Smart Home"
    },
    {
        title:"2006",
        image:"https://i.imgur.com/EQNPYEV.jpg",
        text:"EESTEC Olympics"
    },
    {
        title:"2006",
        image:"https://i.imgur.com/3UZP3xS.jpg",
        text:"EESTEC Olympics Winter Edition"
    },
    {
        title:"2008",
        image:"https://i.imgur.com/4ZGPGcp.png",
        text:"do IT safe"
    },
    {
        title:"2009",
        image:"https://i.imgur.com/TsNgL9w.png",
        text:"RoSummer Spirit"
    },
    {
        title:"2009",
        image:"https://i.imgur.com/S0oDCNJ.jpg",
        text:"EESTEC Art"
    },
    {
        title:"2009",
        image:"https://i.imgur.com/KXG0YL4.jpg",
        text:"Energy Always a Challenge 3"
    },
    {
        title:"2011",
        image:"https://i.imgur.com/g0hVutY.jpg",
        text:"EESTEC Olympics 3"
    },
    {
        title:"2011",
        image:"https://i.imgur.com/3wJHt8t.png",
        text:"EESTEC Art 2"
    },
    {
        title:"2012",
        image:"https://i.imgur.com/TsNgL9w.png",
        text:"Embedded"
    },
    {
        title:"2012",
        image:"https://i.imgur.com/O6RdLrs.png",
        text:"Soft Skills Academy"
    },
    {
        title:"2013",
        image:"https://i.imgur.com/h2oeUNN.png",
        text:"EESTEC Olympics 4"
    },
    {
        title:"2014",
        image:"https://i.imgur.com/n3rd8QX.png",
        text:"Soft Skills Academy 2"
    },
    {
        title:"2014",
        image:"https://i.imgur.com/TsNgL9w.png",
        text:"BeLinked"
    },
    {
        title:"2014",
        image:"https://i.imgur.com/JhdjRJC.png",
        text:"EESTEC Olympics 5"
    },
    {
        title:"2015",
        image:"https://i.imgur.com/G2LcUkd.png",
        text:"Soft Skills Academy 3"
    },
    {
        title:"2015",
        image:"https://i.imgur.com/coqCm29.jpg",
        text:"Energy Always a Challenge 4"
    },
    {
        title:"2015",
        image:"https://i.imgur.com/5cPSw5X.jpg",
        text:"networKING"
    },
    {
        title:"2015",
        image:"https://i.imgur.com/eGxc1hR.jpg",
        text:"EUROtrip"
    },
    {
        title:"2015",
        image:"https://i.imgur.com/DiySBq8.png",
        text:"EESTEC Olympics 6"
    },
    {
        title:"2015",
        image:"https://i.imgur.com/4ZGPGcp.png",
        text:"do IT safer"
    },
    {
        title:"2016",
        image:"https://i.imgur.com/SSSjFHo.png",
        text:"Embedded Minds"
    },
    {
        title:"2016",
        image:"https://i.imgur.com/OfkVnkH.png",
        text:"EESTEC Olympics 7"
    },
    {
        title:"2017",
        image:"https://i.imgur.com/DihHuHI.png",
        text:"Training4Trainers"
    },
    {
        title:"2017",
        image:"https://i.imgur.com/fQbNSUJ.png",
        text:"Soft Skills Academy 4"
    },
    {
        title:"2017",
        image:"https://i.imgur.com/aFBG9BJ.jpg",
        text:"de<code> IT"
    },
    {
        title:"2017",
        image:"https://i.imgur.com/CPmNvsc.jpg",
        text:"EESTEC Olympics 8"
    },
    {
        title:"2018",
        image:"https://i.imgur.com/mPb2Pab.png",
        text:"Soft Skills Academy 5"
    },
    {
        title:"2018",
        image:"https://i.imgur.com/jmD881H.jpg",
        text:"de<code> IT2"
    },
    {
        title:"2018",
        image:"https://i.imgur.com/YlAcrSl.png",
        text:"Innovative Development of Entrepreneurial Abilities"
    },
    {
        title:"2019",
        image:"https://i.imgur.com/nNS0RgV.png",
        text:"EESTEC Olympics 9"
    },
    {
        title:"2019",
        image:"https://i.imgur.com/aFBG9BJ.jpg",
        text:"de<code> IT 3"
    },
    {
        title:"2020",
        image:"https://i.imgur.com/Y0kqTMC.png",
        text:"SoftSkillsAcademy 6"
    }
];

export {events};
