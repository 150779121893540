<template>
  <div id="home-hero-mobile">
    <div class="image">
      <img src="https://i.imgur.com/t9jv4oS.jpg" />
    </div>
    <div class="icon-card-container">
      <div class="icon-card">
        <img src="https://i.imgur.com/kqQiJr5.png" alt class="home-hero-mobile-card-image" />
        <h1 class="home-hero-mobile-card-title">{{$t('cards.homeCards.title1')}}</h1>
        <p class="home-hero-mobile-card-content">{{$t('cards.homeCards.content1')}}</p>
      </div>
      <div class="icon-card">
        <img src="https://i.imgur.com/JWQfHBT.png" class="home-hero-mobile-card-image" />
        <h1 class="home-hero-mobile-card-title">{{$t('cards.homeCards.title2')}}</h1>
        <p class="home-hero-mobile-card-content">{{$t('cards.homeCards.content2')}}</p>
      </div>
      <div class="icon-card">
        <img src="https://i.imgur.com/9ZpveeZ.png" class="home-hero-mobile-card-image" />
        <h1 class="home-hero-mobile-card-title">{{$t('cards.homeCards.title3')}}</h1>
        <p class="home-hero-mobile-card-content">{{$t('cards.homeCards.content3')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import HeroCard from "@/components/cards/HomeCard";

export default {
  components: {
    HeroCard
  }
};
</script>

<style lang="scss" scoped>
#home-hero-mobile {
  position: relative;
  overflow: hidden;
}

.homeImage {
  background-size: cover;
  margin-bottom: 0px;
}

.homeCards {
  overflow: hidden;
  background-color: transparent;
}

.icon-card {
  width: 45%;
  padding-left: 5%;
  padding-right: 5%;
}

.icon-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3vh;
  margin-bottom: 5vh;
}

.home-hero-mobile-card-title {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  font-size: 16px;
}

.home-hero-mobile-card-content {
  align-items: center;
  width: 100%;
  font-size: 14px;
  text-align: center;
}

.home-hero-mobile-card-image {
  height: 30px;
  margin-top: 3px;
}
</style>