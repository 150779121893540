export default {
    setPartners: (state, data) => {
        state.partners = data.sort(categorySorter);
        state.loadingPartners = false;
    }
}

function categorySorter(category1, category2) {
    if (category1.order < category2.order) {
        return -1;
    } else if (category1.order > category2.order) {
        return 1
    }
    return 0;
}