<template>
    <div class = "home-cards">
        <div class = "home-hero-card-container">
            <div class = "home-hero-card" :class="{active: card1}">
                <img src="https://i.imgur.com/kqQiJr5.png" class="home-hero-card-image" />
                <h1 class = "home-hero-card-title">{{$t('cards.homeCards.title1')}}</h1>
                <p class = "home-hero-card-content">{{$t('cards.homeCards.content1')}}</p>
            </div>
            <div class = "home-hero-card" :class="{active: card2}">
                <img src="https://i.imgur.com/JWQfHBT.png" class = "home-hero-card-image" />
                <h1 class = "home-hero-card-title">{{$t('cards.homeCards.title2')}}</h1>
                <p class = "home-hero-card-content">{{$t('cards.homeCards.content2')}}</p>
            </div>
            <div class = "home-hero-card" :class="{active: card3}">
                <img src="https://i.imgur.com/9ZpveeZ.png" class = "home-hero-card-image"/>
                <h1 class = "home-hero-card-title">{{$t('cards.homeCards.title3')}}</h1>
                <p class = "home-hero-card-content">{{$t('cards.homeCards.content3')}}</p>
            </div>
        </div>
        <div class = "intersect" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"></div> 
    </div>
</template>

<script>
export default {
    data() {
        return {
            card1: false,
            card2: false,
            card3: false,
            intersectionOptions: {
                root: null,
                rootMargin: '0px 0px 0px 10px',
                thresholds: [0]
            } 
        }
    },
    methods: {
        onWaypoint ({ going, direction }) {
            if ( (going === this.$waypointMap.GOING_IN || going === this.$waypointMap.GOING_OUT)  && 
            (direction === this.$waypointMap.DIRECTION_TOP || direction === this.$waypointMap.DIRECTION_BOTTOM)) {
                this.startAnimation();
            }
        },
        startAnimation() {
            setTimeout(() => {
                this.card1 = true;
                setTimeout(() => {
                    this.card2 = true;
                    setTimeout(() => {
                        this.card3 = true;
                    }, 250)
                }, 250)
            }, 0)
        }
    }
}
</script>

<style lang="scss" scoped>

.home-hero-card-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 390px;
    padding-bottom: 10px;   
}
.home-hero-card {
    @extend %card-shadow;
    width: 25%;
    height: 70%;
    border-radius: 30px;
    background-color: white;
    word-wrap: break-word;
    padding: 0 10px;
    transform: translateY(50px);
    opacity: 0;
    transition: 1s all;
}

.active {
    opacity: 1;
    transform: translateY(0);
}
.home-hero-card-image {
    height: 70px;
    margin-top: 20px;
}
.home-hero-card-title {
    font-size: 20px;
    font-family: $primary-font;
    margin: 20px;
}

.intersect {
    position: relative;
    bottom: 50px;
}
</style>