<template>
  <div id="home">
    <HomeHero class="is-hidden-mobile"/>
    <HomeHeroMobile class="is-hidden-desktop" />
    <Numbers />
    <HomeSlider class="is-hidden-mobile"></HomeSlider>
    <MobileHomeSlider class="is-hidden-desktop" />
    <ArticlesSlider></ArticlesSlider>
    <Partners />
  </div>
</template>

<script>

import HomeHero from '@/components/heroes/HomeHero'
import Numbers from '@/components/widgets/Numbers'
import HomeSlider from '@/components/sliders/HomeSlider'
import MobileHomeSlider from '@/components/sliders/MobileHomeSlider'
import ArticlesSlider from '@/components/sliders/ArticlesSlider'
import HomeHeroMobile from '@/components/heroes/HomeHeroMobile'

import Partners from '@/pages/Partners'


export default {
  components:{
    HomeHero,
    Numbers,
    HomeHeroMobile,
    HomeSlider,
    Partners,
    MobileHomeSlider,
    ArticlesSlider
  },
  data () {
      return {
      }
  }
}
</script>

<style lang="scss" scoped>
#home{
  padding-top: 70px;
}
</style>
