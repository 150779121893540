<template>
    <div id="home-slider-mobile">
      <h1 class="title-slider">{{$t("cards.timelineHome")}}</h1>
      <flickity ref="flickity" class="carousel-main" :options="flickityOptions">
        <div class="carousel-cell" v-for="(slide, index) in events" :key="index">
          <img class="main" :src="slide.image">
          <h1 class="content-slider">{{slide.title}}</h1>
          <p class="content-slider">{{slide.text}}</p>
        </div>
      </flickity>
    </div>
</template>

<script>
import Flickity from 'vue-flickity'
import { events } from '@/assets/resources/homeSlides.js'

export default {

    components:{
      Flickity
    },
    data() {
        return {
            events,
            flickityOptions: {
            initialIndex: 0,
            prevNextButtons: true,
            pageDots: false,
            wrapAround: true,
            autoPlay: 2000
        },
    }
  },
}
</script>

<style lang="scss">
#home-slider-mobile{
  .title-slider{
    margin:2%;
    font-size: 3vh;
    font-weight: bold;
  }
  .content-slider{
    font-weight: bold;
    font-size: 2.5vh;
  }
  .flickity-viewport{
    min-height: 60vh!important;
  }
  .carousel-main{
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 10px;
  }

  .flickity-slider {
    bottom: 10px;
  }

  .flickity-prev-next-button.previous {
    left: -5vw;
  }
  .flickity-prev-next-button.next {
    right: -5vw;
  }

  img{
    height: 70%;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .carousel-cell {
    @extend %card-shadow;
    margin-top: 40px;
    width: 25vw;
    height: 450px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid grey;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    p {
      min-height: 80px;
    }
  }

  .flickity-button {
    background: transparent;
  }
  .flickity-button:hover {
    background: transparent!important;
    color: lighten(#000, 50%);
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .carousel-cell {
      margin: 0;
      width: 72vw;
      margin: 0 20px;
      margin-top: 50px;
    }


  }
}
</style>
