<template>
    <div id="home-slider" @mousedown="clickDown($event)" @mouseup="stopAnimations($event)" @mousemove="movement($event)" @mouseleave="stopAnimations($event)">
      <div class = "animation-area">
        <h1> {{$t('cards.timelineHome')}} </h1>
        <div v-if="currentEvent != null" class = "home-timeline-card" :class="{hidden: recordingMovement}">
          <img :src="events[currentEvent].image">
          <p> {{ events[currentEvent].text }} </p>
          <p> {{ events[currentEvent].title }} </p>
        </div>
        <div class = "point point1" :class="{hidden: animations[0].hidden}"><p> {{this.events[this.animations[0].eventIndex].text}} </p> <p> {{this.events[this.animations[0].eventIndex].title}} </p></div>
        <div class = "point point2" :class="{hidden: animations[1].hidden}"><p> {{this.events[this.animations[1].eventIndex].text}} </p> <p> {{this.events[this.animations[1].eventIndex].title}} </p></div>
        <div class = "point point3" :class="{hidden: animations[2].hidden}"><p> {{this.events[this.animations[2].eventIndex].text}} </p> <p> {{this.events[this.animations[2].eventIndex].title}} </p></div>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 1366 182" style="enable-background:new 0 0 1366 182;" xml:space="preserve">
          <path class="timeline-line" d="M0,92c118.21-54.86,172.41-41.63,197-29c8.02,4.12,36.57,20.33,63,36c53.64,31.8,51.94,33.7,64,38
            c40.38,14.4,79.4,0.17,132-19c66.11-24.1,73.15-42.87,118-45c12.19-0.58,43.27-2.05,77,13c41.08,18.34,44.66,42.7,76,46
            c13.5,1.42,32-6.05,69-21c42.33-17.1,41.12-20.9,59-26c47.16-13.46,91.2,2.8,108,9c56.24,20.76,53.61,47.26,95,54
            c32.69,5.32,60.02-7.03,102-26c57.98-26.2,63.9-45.76,100-53c22.2-4.45,57.16-4.52,106,23"/>
        </svg>
      </div>
      <div class = "non-clickable-overlay">
      </div>
    </div>
</template>

<script>
import Flickity from 'vue-flickity'
import { events } from '@/assets/resources/homeSlides.js'

export default {
  components:{
    Flickity
  },
  props : [
    'slides'
  ],
  data() {
    return {
      events,
      recordingMovement: false,
      totalDuration: 6000,
      movementRightLocked: false,
      movementLeftLocked: false,
      checkPoints: [
        {
          value: 1000,
        },
        {
          value: 3000,
        },
        {
          value: 5000,
        }
      ],
      animations: [
        {
          animationData: null,
          movingSeek: null,
          movementStart: null,
          initialSeek: 1000,
          lastSeek: null,
          currentCheckpoint: 0,
          eventIndex: null,
          hidden: false
        },
        {
          animationData: null,
          movingSeek: null,
          movementStart: null,
          initialSeek: 3000,
          lastSeek: null,
          currentCheckpoint: 1,
          eventIndex: null,
          hidden: false
        },
        {
          animationData: null,
          movingSeek: null,
          movementStart: null,
          initialSeek: 5000,
          lastSeek: null,
          currentCheckpoint : 2,
          eventIndex: null,
          hidden: false
        }
      ]
    }
  },
  computed: {
    currentEvent: function() {
      let middlePoint = this.animations.filter(anim => anim.currentCheckpoint === 1)[0];
      let currentEventIndex = middlePoint ? middlePoint.eventIndex : null;
      return currentEventIndex;
    }
  },
  methods: {
    clickDown($event) {
      for (let animation of this.animations) {
        animation.movementStart = $event.x;
      }
      this.recordingMovement = true;
    },
    stopAnimations($event) {
      if(this.recordingMovement) {
        this.movementRightLocked = this.movementLeftLocked = false;
        this.recordingMovement = false;
        for(let animation of this.animations) {
          if (animation.movingSeek != null) {
            animation.lastSeek = animation.movingSeek;
            this.moveDotToCheckpoint(animation, this.checkPoints[animation.currentCheckpoint].value);
          }
        }
      }
    },
    moveDotToCheckpoint(animation, checkPoint) {
      if(animation.movingSeek < checkPoint) {
        let interval = setInterval(() => {
          if(!this.recordingMovement) {
            if (animation.movingSeek + 30 > checkPoint) {
              animation.movingSeek += checkPoint - animation.movingSeek;
            } else {
              animation.movingSeek += 30;
            }
            animation.lastSeek = animation.movingSeek;
            animation.animationData.seek(animation.movingSeek);
            if (animation.movingSeek >= checkPoint) {
              animation.lastSeek = animation.movingSeek;
              clearInterval(interval);
            }
          } else {
            clearInterval(interval);
          }
        }, 15);
      } else {
        if(animation.movingSeek > checkPoint) {
          let interval = setInterval(() => {
            if (!this.recordingMovement) {
              if (animation.movingSeek - 30 < checkPoint) {
                animation.movingSeek -= animation.movingSeek - checkPoint;
              } else {
                animation.movingSeek -= 30;
              }
              animation.lastSeek = animation.movingSeek;
              animation.animationData.seek(animation.movingSeek);
              if(animation.movingSeek <= checkPoint) {
                animation.lastSeek = animation.movingSeek;
                clearInterval(interval);
              }
            } else {
              clearInterval(interval);
            }
          }, 15)
        }
      }
    },
    changeCheckpoint(animation) {
      let oldCheckpoint = animation.currentCheckpoint;
      let movementLock = this.movementLeftLocked || this.movementRightLocked;
      if (animation.movingSeek >= 0 && animation.movingSeek < 2000) {
        animation.currentCheckpoint = 0;
      } else if (animation.movingSeek >= 2000 && animation.movingSeek < 4000) {
        animation.currentCheckpoint = 1;
      } else if (animation.movingSeek >= 4000 && animation.movingSeek <= 6000) {
        animation.currentCheckpoint = 2;
      }

      let animationIndex = this.animations.indexOf(animation);
      if (animationIndex == 2) {
        let animationsSortedByEvents = [...this.animations];
        animationsSortedByEvents.sort((anim1, anim2) => anim1.eventIndex - anim2.eventIndex);

        if(this.animations[0].currentCheckpoint === this.animations[1].currentCheckpoint || this.animations[1].currentCheckpoint == this.animations[2].currentCheckpoint 
        || this.animations[0].currentCheckpoint === this.animations[2].currentCheckpoint) {
          this.animations.forEach(anim => anim.currentCheckpoint = animationsSortedByEvents.indexOf(anim));
        }
      }
    }, 
    checkPointFailsafeRight(animation) {
      if(animation.movingSeek > 2000 && animation.movingSeek < 3000) {
        animation.currentCheckpoint = 0;
      } else if(animation.movingSeek > 4000 && animation.movingSeek < 5000) {
        animation.currentCheckpoint = 1;
      }
    },
    checkPointFailsafeLeft(animation) {
      if(animation.movingSeek > 3500 && animation.movingSeek < 6000) {
        animation.currentCheckpoint = 2;
      }
    },
    changeIndex(animation, direction) {
      if (animation.hidden) {
        animation.hidden = false;
      } else {
        let newIndex = animation.eventIndex + 3 * direction;

        if (newIndex < 0 || newIndex >= this.events.length) {
          animation.hidden = true;
        } else {
          animation.eventIndex = newIndex;
        }
      }

    },
    movement($event) {
      if(this.recordingMovement) {
        for(let animation of this.animations) {
          let difference = null;
          let percentage = null;
          let history = animation.lastSeek;

          if ($event.x < animation.movementStart && !this.movementLeftLocked) { 
            this.movementRightLocked = false;
            difference = $event.x - animation.movementStart;
            percentage = (difference * 100) / window.innerWidth;
            animation.movingSeek = history + animation.animationData.duration * percentage / 100;

          } else if ($event.x > animation.movementStart && !this.movementRightLocked){

            this.movementLeftLocked = false;
            difference = animation.movementStart - $event.x;
            percentage = (difference * 100) / window.innerWidth;
            animation.movingSeek = history - animation.animationData.duration * percentage / 100;
          }

          if(animation.movingSeek > this.totalDuration && !this.movementRightLocked) {
            if(animation.eventIndex == 1) {
              this.movementRightLocked = true;
            } else {
              animation.movingSeek = 0;
              animation.lastSeek = 0;
              animation.movementStart = $event.x;
              this.changeIndex(animation, -1);
            }
          } else if (animation.movingSeek < 0 && !this.movementLeftLocked) {

            if(animation.eventIndex == this.events.length - 2) {
              this.movementLeftLocked = true;
            } else {
              animation.movingSeek = this.totalDuration;
              animation.lastSeek = this.totalDuration;
              animation.movementStart = $event.x;
              this.changeIndex(animation, 1);
            }
          }

          if (this.movementLeftLocked || this.movementRightLocked) {
            animation.movementStart = $event.x;
            animation.lastSeek = animation.movingSeek;

            if(this.movementRightLocked) {
              this.checkPointFailsafeRight(animation);
            } else if (this.movementLeftLocked) {
              this.checkPointFailsafeLeft(animation);
            }
          } 

          if (!this.movementLeftLocked && !this.movementRightLocked) {
            this.changeCheckpoint(animation);
          }

          animation.animationData.seek(animation.movingSeek);
        }
      }
    }
  },
  mounted() {
    let path = this.$anime.path('.timeline-line');
    this.animations.forEach((animation, index) => {
      animation.animationData = this.$anime({
        targets: `.point${index + 1}`,
        translateX: path('x'),
        translateY: path('y'),
        easing: 'linear',
        duration: this.totalDuration,
        loop: true,
        autoplay: false
      });

      animation.animationData.seek(animation.initialSeek);
      animation.lastSeek = animation.initialSeek;
    })

    // console.log(this.animations);
  },
  created() {
    this.animations[0].eventIndex = this.events.length - 3;
    this.animations[1].eventIndex = this.events.length - 2;
    this.animations[2].eventIndex = this.events.length - 1;
  }
}
</script>

<style lang="scss" scoped>
#home-slider{
  position: relative;
  cursor: grab;
  height: 600px;
  // width: 100vh;
  padding-top: 20px;
  padding-bottom: 200px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  .animation-area {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .non-clickable-overlay {
    position: absolute;
    z-index: 50;
    width: 100%;
    height: 100%;
  }


  .timeline-line{
    fill:none;
    stroke:$primary-color;
    stroke-miterlimit:10;
  }
  
  h1 {
    font-size: 32px;
    font-weight: bold;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  svg {
    margin-top: 100px;
  }

  .home-timeline-card {
    @extend %card-shadow;
    width: 300px;
    height: 400px;
    position: absolute;
    left: calc(50% - 150px);
    top: 120px;
    z-index: 20;
    opacity: 1;
    transition: 0.3s all;
    background-color: #fff;
    border: 1px solid gray;
    border-radius: 10px;

    img, p {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    }
    img {
      height: 70%;
      width: 100%;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }

    p {
      font-size: 20px;
    }
    p:nth-child(2) {
      margin-top: 10px;
      font-weight: bold;
    }
  }
  
  .point {
    width: 18px;
    height: 18px;
    top: 139px;
    left: -9px;
    border-radius: 50%;
    background-color: darken($primary-color, 10%);
    position: absolute;
    overflow: visible;
    z-index: 15;

    p {
      position: absolute;
      bottom: 70px;
      font-weight: bold;
      // left: 20px;
      right: -100px;
      width: 200px;
      text-align: center;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }

    p:nth-child(2) {
      bottom: 40px;
    }
  }

  .hidden {
    opacity: 0;
  }

  &:active {
    cursor: grabbing;
  }
}
</style>