<template>
  <div id="app">
    <Navbar />
    <div class="page">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/global-components/Navbar";
import Footer from "@/components/global-components/Footer";

export default {
  components: {
    Navbar,
    Footer
  }
};
</script>

<style lang="scss">
.page {
  position: relative !important;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: $primary-color;
    &.router-link-exact-active {
      color: $secondary-color;
    }
  }
}

router-view {
  height: 90vh;
}
</style>
