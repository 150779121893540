import Vue from 'vue'
import Router from 'vue-router'
import Home from './pages/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('./pages/About.vue')
    },
    {
      path: '/events',
      name: 'Events',
      component: () => import('./pages/Events.vue')
    },
    {
      path: '/international',
      name: 'International',
      component: () => import('./pages/International.vue')
    },
    {
      path: '/news',
      name: 'News',
      component: () => import('./pages/News.vue')
    },
    {
      path: '/aplica',
      name: 'Register',
      component: () => import('./pages/Register.vue')
    },
    ,
    {
      path: '/Telecom Academy',
      name: 'Telecom Academy',
      component: () => import('./pages/articles/Telecom.vue')
    },
    {
      path: '/Romanian Kitsch Museum',
      name: 'Romanian Kitsch Museum',
      component: () => import('./pages/articles/Kitsch.vue')
    },
    {
      path: "/redirectTest",
      name:"redirectTest",
      beforeEnter(to, from, next) {
        // Put the full page URL including the protocol http(s) below
        window.location.replace("https://ssa.eestec.ro/")
      }
    },
    {
      path: '*',
      name: '404',
      component: () => import('./pages/FourOhFour.vue')
    }
  ],
  scrollBehavior () {
    return {x: 0, y: 0}
  }
})
