<template>
  <div id="numbersSection">
    <div class="columns">
      <div class="column has-text-centered has-text-justified" v-for="(section, index) in numbers" :key="index">
        <img class = "pictogram" :src = "section.image">
        <h2>{{$t(section.text)}}</h2>
        <h1>{{section.number}}</h1>
      </div>
    </div>
    <div v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"></div>
  </div>
</template>

<script>
import numbers from '@/assets/resources/numbers.js'
export default {
  data () {
    return {
      numbers,
      triggered: false,
      animationDuration: 2500,
      count: 0,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        thresholds: [0]
      } 
    }
  },
  methods: {
    onWaypoint ({ going, direction }) {
      if ((going === this.$waypointMap.GOING_IN || going === this.$waypointMap.GOING_OUT) && 
      (direction === this.$waypointMap.DIRECTION_TOP || direction === this.$waypointMap.DIRECTION_BOTTOM)) {
        this.startAnimation();
      }
    },
    startAnimation: function () {
      if (!this.triggered) {
        this.triggered = true
        var interval = setInterval(() => {
          this.count += 50;
          this.numbers.forEach((section) => {
            section.number = Math.floor(this.count / this.animationDuration * section.value)
          })
          if (this.count >= this.animationDuration) {
            clearInterval(interval)
          }
        }, 50)
      }
    }
  }
}
</script>

<style scoped lang = "scss">
#numbersSection {
  
  background-color: $primary-color;
}

.columns{
  margin-bottom:0px!important;
}

h1 {
  text-align: center;
  font-size: 30px;
  margin-bottom: 0px;
  color: $secondary-color;
}
h2 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 1rem;
  color: $secondary-color;
}
.content h2:not(:first-child) {
    margin-top: 0.5rem;
}
.content h1:not(:first-child) {
    margin-top: 0.5rem;
}
.pictogram {
  height: 40px;
  width: 40px;
  margin-bottom:  23px !important;
  display: block;
  margin: auto;
}
</style>