let numbers = [
    {
        image: "https://i.imgur.com/FV1ZuvK.png",
        number: 0,
        value: 17,
        text:   'widgets.numbers.years'
    },
    {
        image: "https://i.imgur.com/TsMDEU9.png",
        number: 0,
        value: 73,
        text: 'widgets.numbers.active_memebers'
   },
   {
        image: "https://i.imgur.com/y7GGhUz.png",
        number: 0,
        value: 39,
        text: 'widgets.numbers.events'
    },
    {
        image: "https://i.imgur.com/4rcEHx1.png",
        number: 0,
        value: 54,
        text: 'widgets.numbers.branches'
    }
]

export default numbers;
