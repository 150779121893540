<template>
  <div id="articles-slider">
    <h1 class="slider-title">Latest Articles</h1>
    <flickity class="carousel" ref="flickity" :options="flickityOptions">
      <div class="carousel-cell">
        <div class="article-preview">
          <p class="article-title telecom">Telecom Academy – The future is in IT!</p>
          <p class="article-redirect" @click="redirectTelecom()">View Article</p>
          <img class="main-image" :src="path1"/>
        </div>
      </div>
      <div class="carousel-cell">
        <div class="article-preview">
          <p class="article-title kitsch">Romanian Kitsch Museum</p>
          <p class="article-title kitsch">numărul 1 pe Tripadvisor</p>

          <p class="article-redirect" @click="redirectKitschMuseum()">View article</p>
          <img class="main-image" :src="path2"/>
        </div>
      </div>
    </flickity>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";
import Flickity from "vue-flickity";

export default {
  components: {
    Flickity,
    VueAgile,
  },
  data() {
    return {
      path1: "https://i.imgur.com/8aR5H1k.png",
      path2: "https://i.imgur.com/yG9m9Jj.jpg",
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: true,
        wrapAround: false,
      },
    };
  },
  methods: {
    redirectTelecom() {
      this.$router.push({
        name: 'Telecom Academy'
      });
    },
    redirectKitschMuseum() {
      this.$router.push({
        name: 'Romanian Kitsch Museum'
      });
    },
  },
};
</script>

<style lang="scss">
.flickity-prev-next-button {
  width: 100px;
  height: 100px;
}

.flickity-prev-next-button.previous {
  left: 20vw;
}

.flickity-prev-next-button.next {
  right: 20vw;
}

.slider-title {
  margin-bottom: 5vh;
  font-size: 5vh;
  font-weight: bold;
  font-family: $primary-font;
}

.carousel-cell {
  height: 50vh;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
}

.article-preview {
  position: relative;
  width: 50%;
}

.article-title  {
  position: absolute;
  font-family: $primary-font;
  font-size: 4vh;
  color: #edf6fb;
  z-index: 100;
}

.telecom {
  top: 1%;
  left: 15%;
}

.kitsch:first-child{
  top: 1%;
  left: 27%;
}
.kitsch:nth-child(2){
  top: 10%;
  left: 28%
}


.article-redirect {
  position: absolute;
  font-family: $primary-font;
  right: 15%;
  bottom: 5%;
  font-size: 4vh;
  color: #0645ad;
  text-shadow: 0.1vw 0.1vw 0.1vw rgba(16.1%, 19.6%, 32.9%, 0.5);
}

.article-redirect:hover {
  cursor: pointer;
  color: #edf6fb;
  transition: 0.2s all;
  text-shadow: 0.1vw 0.1vw 0.1vw rgba(16.1%, 19.6%, 32.9%, 0.5);
}

.main-image {
  height: 50vh;
  box-shadow: 0.1vw 0.1vw 1vw rgba(16.1%, 19.6%, 32.9%, 0.5);
}

@media (max-width: 900px){

  .carousel-cell {
  height: 70vh;
  }
  .article-preview {
    width: 90%;
    box-shadow: 1vw 1vw 1vw rgb(16.1%, 19.6%, 32.9%);
    margin-bottom: 10%;
    border: 1px solid rgb(16.1%, 19.6%, 32.9%);

  }
  .flickity-prev-next-button.previous {
  left: 0vw;
}

.flickity-prev-next-button.next {
  right: 0vw;
}
.main-image {
  width: 90%;
  margin-top: 50%;
  height: auto;
  margin-bottom: 100vh;
}

.article-title{
  color: black;
  font-weight: bold;
  top:10%;
  left:0%;
  font-size: 3vh;
  margin: 5% 2% 0 2%;
}
}
</style>
