<template>
    <div class = "navbar">
        <div class = "home-logo">
            <router-link to="/"><img :src="home"></router-link>    
            <div class="languages">
                <div class="eng"><a :class="{'active-language': isEnglish}" @click="changeLang('en')">EN</a></div>
                <div class="ro"><a  :class="{'active-language': isRomanian}" @click="changeLang('ro')">RO</a></div>
            </div>
        </div>

        <div class ="eestec-logo">
            <img :src="logo">
        </div>

        <b-dropdown aria-role="list" position="is-bottom-left" class ="side-menu">
            <button class="button" slot="trigger" id="hamburger" aria-controls="dropdown-menu" v-bind:class="{'active-button':buttonIsActive}"  v-on:click='toggle' >
                <b-icon icon="menu" size="is-medium"></b-icon>
            </button>
                    <b-dropdown-item class="menu-items" aria-role="listitem">
                        <router-link class="router-link-dropdown" to="/">{{$t('navbar.navbar-items.home')}}</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item class="menu-items" aria-role="listitem">
                        <router-link class="router-link-dropdown" to="/about">{{$t('navbar.navbar-items.about')}}</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item class="menu-items" aria-role="listitem">
                        <router-link class="router-link-dropdown" to="/events">{{$t('navbar.navbar-items.events')}}</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item class="menu-items" aria-role="listitem">
                        <router-link class="router-link-dropdown" to="/international">{{$t('navbar.navbar-items.international')}}</router-link>
                    </b-dropdown-item>
                    <b-dropdown-item class="menu-items" aria-role="listitem">
                        <router-link class="router-link-dropdown" to="/aplica">{{$t('navbar.navbar-items.register')}}</router-link>
                    </b-dropdown-item>
        </b-dropdown>
    </div> 
</template>
<script>
export default {
        data() {
            return {
                buttonIsActive: false,
                menuIsActive: true,
                logo: require("@/assets/images/logo-eestec.png"),
                home: require("@/assets/images/home-icon-silhouette.svg"),
                isEnglish: false,
                isRomanian: false
            } 
        },
        methods: {
            toggle() {
                this.buttonIsActive = !this.buttonIsActive;
            },
            changeLang(lang) {
                this.$i18n.locale = lang;
                if(this.$i18n.locale === 'en') {
                    this.isEnglish = true;
                    this.isRomanian = false;
                } else {
                    this.isEnglish = false;
                    this.isRomanian = true;
                }
                // console.log(this.$i18n);
            }
        },
        created() {
            if(this.$i18n.locale === 'en') {
                this.isEnglish = true;
                this.isRomanian = false;
            } else {
                this.isEnglish = false;
                this.isRomanian = true;
            }
        }
}
</script>

<style lang="scss">

.navbar {
    position: fixed !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary-color !important;
    height: 88px;
    padding: 18px 20px;
    padding-right: 0;
    width: 100%;
    z-index: 100 !important;

    & > div {
        width: 33%;
        padding-right: 20px;
    }

    & > div:last-child {
        display: flex;
        justify-content: flex-end;
    }
    
    span.icon {
        color: #fff;
    }

    .button {
        border: none;
        width: 40px;
        height: 40px;
        background-color: $primary-color;
    }

    .dropdown-content {
        background-color: $primary-color !important;
    }

    .dropdown-item { 
        padding: 0;
    }

    a {
        color: #fff;
        background-color: $primary-color;
        font-size: 14px;        
    }

    .menu-items{
        height: 50px;
        a {
            height: 30px;
            display: flex;
            align-items: center;
        }
    }
    .menu-items:hover {
        background-color: #fff;
        a{
            color: $primary-color;
            background-color: #fff;
        }
    }

    .router-link-dropdown {
        height: 100% !important;
        width: 100%;
        display: block;
        padding-left: 1rem;
    }
}

.eestec-logo {
    height: 100%;
    img {
        height: 100%;
    }
    position: relative;
}

.home-logo {
    height: 100%;
    text-align: left;
    background-color: $primary-color;
    display: flex;

    & > a {
        display: flex;
        align-content: center;
    }

    img {
        width: 32px;
        color: #fff;
        fill: #fff;
    }

    .languages {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    a:hover {
        color: darken(#fff, 20%);
    }

    .active-language {
        text-decoration: underline;
    }

}

.side-menu {
    justify-content: end;
    
    & > div {
        align-items: center;
        display: flex;
    }
}


</style>


