<template>
    <div id = "home-hero">
        <div class="homeImage">
            <parallax :speedFactor="0.3">
                <img :src="img">
            </parallax>
            <div class="homeCards">
                 <HeroCard />
            </div>
            <div class="red"> 

            </div>
        </div> 
    </div>
</template>

<script>

import HeroCard from '@/components/cards/HomeCard'
import Parallax from 'vue-parallaxy'

export default {
    components:{
        HeroCard,
        Parallax
    },
    data() {
        return {
            img: "https://i.imgur.com/R2mY65V.jpg"
        }
    }
    
}
</script>

<style lang="scss" scoped>
#home-hero{
    position:   relative;
}

.homeImage{
    background-size: cover;
    margin-bottom: 0px;
}

.red{
    height: 300px;
    background-size: cover;
    background-color: $primary-color;
}

.homeCards{
    overflow: hidden;
    background-color: transparent;
    bottom:100px;
    position: absolute;
    width: 100%;
}
</style>