import Vue from 'vue'
import App from './App.vue'
import router from './router'
import en from './assets/locales/en.json'
import ro from './assets/locales/ro.json'
import VueI18n from 'vue-i18n'
import VueAnalytics from 'vue-analytics'
import Flickity from 'vue-flickity'
import VueAgile from 'vue-agile'

import VueWaypoint from 'vue-waypoint'
import VueScrollTo from 'vue-scrollto'
import VueAnime from 'vue-animejs';

import VueResource from 'vue-resource'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import store from './store/index.js'



Vue.use(Buefy)
Vue.use(VueResource)
Vue.use(VueWaypoint)
Vue.use(VueI18n)
Vue.use(VueScrollTo)
Vue.use(VueAnime)
Vue.use(Flickity)//nu cred ca mai e nevoie de asta
Vue.use(VueAgile)

Vue.use(VueAnalytics, {
  id: 'UA-108218525-1',
  router
})

Vue.config.productionTip = false

const messages = {
  en,
  ro
}

const i18n = new VueI18n({
  locale: 'ro',
  messages
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')



